* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto';
}

body {
  background-color: #f4f6f8;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

.show-only-on-print {
  display: none;  
  img {
    height: 25px;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .no-space {
    font-size: 12px;
  }  
  &.header {
    padding: 5px;
    border: 1px solid #000;
  }
  &.footer {
    padding: 3px;
    border: 1px solid #000;
    border-top: none;

    .left {
      justify-content: left!important;
    }

    .right {
      height: 45px;
      align-items: flex-end;
      justify-content: right!important;
    }
  }
}

@media print {
  body * {
    visibility: hidden;
  }

  .section-to-print {
    padding: 30px;
  }

  .section-to-print,
  .section-to-print * {
    visibility: visible;
  }

  button, .not-print, .MuiFormGroup-root {
    display: none!important;
  }

  .show-only-on-print {
    display: block!important;
  }

  .MuiTableCell-root, .MuiTableCell-body {
    padding: 0px!important;
  }
  
  .MuiTable-root {
    border: 1px solid #000!important;
    border-top: none!important;
    border-collapse: unset!important;
    border-spacing: 1px!important;
  }

  .MuiTableCell-head {
    border-right: 1px solid #000!important;
  }

  .MuiTableCell-root{
    padding-left: 4px!important;
  }

}
